import { ReactComponent as Copy } from "../bin/assets/copy.svg"
import copy from "copy-to-clipboard"
import hexRgb from "hex-rgb"

const Color = ({ name, hex, id, breakpoints }) => {
    const rgb = hexRgb(hex)

    return (
        <div className={`color ${breakpoints}`}>
            <div className="color-rendered" style={{ "backgroundColor": hex }}>
                <span className="color-title">
                    {name}
                </span>
            </div>
            <div className="colors">
                <span className="color-id code">
                    {id}
                    <Copy onClick={(e) => { copy(`${id}`) }} className="color-copy" />
                </span>
                <span className="color-hex code">
                    {hex}
                    <Copy onClick={(e) => { copy(`${hex}`) }} className="color-copy" />
                </span>
                {/* <span className="color-rgb">
                    {`rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})`}
                    <Copy onClick={(e) => { copy(`rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})`) }} className="color-copy" />
                </span> */}
            </div>
        </div>
    )
}

export default Color