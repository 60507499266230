import React from "react"
import CodeBlock from "../components/CodeBlock"

const circle_solid = `
~~~html
<ul>
    <li>Item</li>
    <li>Item</li>
    <li>Item</li>
</ul>
~~~
`

const circle_regular = `
~~~html
<ul className="list-circle-solid">
    <li>Item</li>
    <li>Item</li>
    <li>Item</li>
</ul>
~~~
`

const check_solid = `
~~~html
<ul className="list-check-solid">
    <li>Item</li>
    <li>Item</li>
    <li>Item</li>
</ul>
~~~
`

const ListStyles = ({ title }) => {
    return (
        <div className="block block-cards">
            <h1>{title}</h1>
            <div className="row">
                <div className="col-4">
                    <h3>Default</h3>
                    <ul>
                        <li>Item</li>
                        <li>Item</li>
                        <li>Item</li>
                    </ul>
                </div>
                <div className="col-4">
                    <h3>Circle Solid</h3>
                    <ul className="list-circle-solid">
                        <li>Item</li>
                        <li>Item</li>
                        <li>Item</li>
                    </ul>
                </div>
                <div className="col-4">
                    <h3>Check Solid</h3>
                    <ul className="list-check-solid">
                        <li>Item</li>
                        <li>Item</li>
                        <li>Item</li>
                    </ul>
                </div>
            </div>
            <div className="row g-4 mt-3">
                <div className="col-12">
                    <h3>Circle Solid</h3>
                    <CodeBlock markdown={circle_solid} />
                </div>
                <div className="col-12">
                    <h3>Circle Regular</h3>
                    <CodeBlock markdown={circle_regular} />
                </div>
                <div className="col-12">
                    <h3>Check Solid</h3>
                    <CodeBlock markdown={check_solid} />
                </div>
            </div>
        </div>
    )
}

export default ListStyles