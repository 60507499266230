import CodeBlock from "../components/CodeBlock"

const oneColumn = `
~~~html
<div class="row">
    <div class="col-12">
        <p>
            Paragraph
        </p>
    </div>
</div>
~~~
`

const twoColumns = `
~~~html
<div className="row">
    <div className="col-12 col-md-6">
        <p>
            Text
        </p>
    </div>
    <div className="col-12 col-md-6">
        <p>
            Text
        </p>
    </div>
</div>
~~~
`

const threeColumns = `
~~~html
<div className="row">
    <div className="col-12 col-md-6 col-lg-4">
        <p>
            Text
        </p>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
        <p>
            Text
        </p>
    </div>
    <div className="col-12 col-md-6 col-lg-4">
        <p>
            Text
        </p>
    </div>
</div>
~~~
`

const Typography = ({ title }) => {
    return (
        <div className="block block-typography">
            <h1 className="title">{title}</h1>
            <div className="row mb-4">
                <div className="col-12 col-md-6">
                    <h2>Roboto (latin, cyrillic)</h2>
                    <span>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z</span>
                    <br></br>
                    <span>a b c d e f g h i j k l m n o p q r s t u v w x y z</span>
                </div>
                <div className="col-12 col-md-6">
                    <h2>Weights</h2>
                    <div className="row">
                        <div className="col-3 fw-light">
                            <div>
                                <span>Aa</span>
                                <br></br>
                                <span>Light / 300</span>
                            </div>
                        </div>
                        <div className="col-3 fw-regular">
                            <div>
                                <span>Aa</span>
                                <br></br>
                                <span>Regular / 400</span>
                            </div>
                        </div>
                        <div className="col-3 fw-medium">
                            <div>
                                <span>Aa</span>
                                <br></br>
                                <span>Medium / 500</span>
                            </div>
                        </div>
                        <div className="col-3 fw-bold">
                            <div>
                                <span>Aa</span>
                                <br></br>
                                <span>Bold / 700</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <h2>Headlines</h2>
                <div className="col-12">
                    <table className="basic-table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Weight</th>
                                <th>Usage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h1 className="m-0">Headline H1</h1>
                                </td>
                                <td className="fw-light">Light / 300</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="m-0">Headline H2</h2>
                                </td>
                                <td className="fw-light">Light / 300</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <h3 className="m-0">Headline H3</h3>
                                </td>
                                <td className="fw-regular">Regular / 400</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <h4 className="m-0">Headline H4</h4>
                                </td>
                                <td className="fw-regular">Regular / 400</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 className="m-0">Headline H5</h5>
                                </td>
                                <td className="fw-regular">Regular / 400</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <h6 className="m-0">Headline H6</h6>
                                </td>
                                <td className="fw-regular">Regular / 400</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h2>Paragraphs</h2>
                <div className="row g-3">
                    <div className="col-12">
                        <h3>One column</h3>
                        <p>
                            Werde auch du Teil der JULABO-Familie und erlebe ein echtes Miteinander in einem tollen Team.
                            Komm zum Experten für Temperiertechnik und meistere gemeinsam mit über 450 engagierten
                            Kolleginnen und Kollegen die Herausforderungen der Zukunft. JULABO – Ein Arbeitsplatz, an dem du
                            dich wie zuhause fühlen kannst.
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeBlock markdown={oneColumn} />
                    </div>
                </div>
                <hr></hr>
                <div className="row g-3">
                    <div className="col-12 col-md-6">
                        <h3>Two columns</h3>
                        <p>
                            Werde auch du Teil der JULABO-Familie und erlebe ein echtes Miteinander in einem tollen Team.
                            Komm zum Experten für Temperiertechnik und meistere gemeinsam mit über 450 engagierten
                            Kolleginnen und Kollegen die Herausforderungen der Zukunft. JULABO – Ein Arbeitsplatz, an dem du
                            dich wie zuhause fühlen kannst.
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <p>
                            Werde auch du Teil der JULABO-Familie und erlebe ein echtes Miteinander in einem tollen Team.
                            Komm zum Experten für Temperiertechnik und meistere gemeinsam mit über 450 engagierten
                            Kolleginnen und Kollegen die Herausforderungen der Zukunft. JULABO – Ein Arbeitsplatz, an dem du
                            dich wie zuhause fühlen kannst.
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeBlock markdown={twoColumns} />
                    </div>
                </div>
                <hr></hr>
                <div className="row g-3">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h3>Three columns</h3>
                        <p>
                            Werde auch du Teil der JULABO-Familie und erlebe ein echtes Miteinander in einem tollen Team.
                            Komm zum Experten für Temperiertechnik und meistere gemeinsam mit über 450 engagierten
                            Kolleginnen und Kollegen die Herausforderungen der Zukunft. JULABO – Ein Arbeitsplatz, an dem du
                            dich wie zuhause fühlen kannst.
                        </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <p>
                            Werde auch du Teil der JULABO-Familie und erlebe ein echtes Miteinander in einem tollen Team.
                            Komm zum Experten für Temperiertechnik und meistere gemeinsam mit über 450 engagierten
                            Kolleginnen und Kollegen die Herausforderungen der Zukunft. JULABO – Ein Arbeitsplatz, an dem du
                            dich wie zuhause fühlen kannst.
                        </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <p>
                            Werde auch du Teil der JULABO-Familie und erlebe ein echtes Miteinander in einem tollen Team.
                            Komm zum Experten für Temperiertechnik und meistere gemeinsam mit über 450 engagierten
                            Kolleginnen und Kollegen die Herausforderungen der Zukunft. JULABO – Ein Arbeitsplatz, an dem du
                            dich wie zuhause fühlen kannst.
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeBlock markdown={threeColumns} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Typography