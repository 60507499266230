import Color from "../components/Color"

const Colors = ({ title, colors, breakpoints }) => {
    return (
        <div className="block block-colors">
            <h1>{title}</h1>
            <div className="row">
                {
                    Object.keys(colors).map(key => {
                        return (
                            <Color key={key} name={key} hex={colors[key].hex} id={colors[key].id} breakpoints={breakpoints} />
                        )
                    })
                }
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="info-block">
                        You can use the color <span className="code">id</span> (e. g. <span className="code">darkEmerald</span>) as a class in combination with the following options: <span className="code">bg</span> <span className="code">color</span> <span className="code">border</span> <span className="code">fill</span> (e. g. <span className="code">bg-darkEmerald</span>).
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Colors