import CodeBlock from "../components/CodeBlock"

const v1 = `
~~~html
<div class="table-container border-dotted border-gray60">
    <table class="table table--v1">
        <tbody>
            <tr>
                <td class="title">
                    <span>Title</span>
                </td>
                <td class="value">
                    <span>Value</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
~~~
`

const v2 = `
~~~html
<div class="table-container">
    <table class="table table--v2">
        <tbody>
            <tr>
                <td class="title">
                    <span>Title</span>
                </td>
                <td class="value">
                    <span>Value</span>
                </td>
                <td class="medium">
                    <span>Medium</span>
                </td>
            </tr>
            <tr>
                <td class="title">
                    <span>Title</span>
                </td>
                <td class="value">
                    <span>Value</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
~~~
`

const Tables = ({ title, colors, breakpoints }) => {
    return (
        <div className="block block-tables">
            <h1>{title}</h1>
            <div className="row mb-3">
                <div className="col-12">
                    <h3>V1</h3>
                    <div className="table-container border-dotted border-gray60">
                        <table className="table table--v1">
                            <tbody>
                                <tr>
                                    <td className="title">
                                        <span>JULABO Gerät</span>
                                    </td>
                                    <td className="value">
                                        <span>PRESTO W55</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        <span>Kälteleistung</span>
                                    </td>
                                    <td className="value">
                                        <div className="table-container">
                                            <table className="table table--v2">
                                                <tbody>
                                                    <tr>
                                                        <td className="title">
                                                            <span>°C</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>20</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>0</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>-10</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>-20</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>-30</span>
                                                        </td>
                                                        <td className="medium">
                                                            <span>Medium: Ethanol</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="title">
                                                            <span>kW</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>0.33</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>0.28</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>0.23</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>0.13</span>
                                                        </td>
                                                        <td className="value">
                                                            <span>0.05</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        <span>Heizleistung</span>
                                    </td>
                                    <td className="value">
                                        <span>15 kW</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        <span>Bandbegrenzung</span>
                                    </td>
                                    <td className="value">
                                        <span>ohne</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <CodeBlock markdown={v1} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3>V2</h3>
                    <div className="table-container">
                        <table className="table table--v2">
                            <tbody>
                                <tr>
                                    <td className="title">
                                        <span>°C</span>
                                    </td>
                                    <td className="value">
                                        <span>20</span>
                                    </td>
                                    <td className="value">
                                        <span>0</span>
                                    </td>
                                    <td className="value">
                                        <span>-10</span>
                                    </td>
                                    <td className="value">
                                        <span>-20</span>
                                    </td>
                                    <td className="value">
                                        <span>-30</span>
                                    </td>
                                    <td className="medium">
                                        <span>Medium: Ethanol</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title">
                                        <span>kW</span>
                                    </td>
                                    <td className="value">
                                        <span>0.33</span>
                                    </td>
                                    <td className="value">
                                        <span>0.28</span>
                                    </td>
                                    <td className="value">
                                        <span>0.23</span>
                                    </td>
                                    <td className="value">
                                        <span>0.13</span>
                                    </td>
                                    <td className="value">
                                        <span>0.05</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <CodeBlock markdown={v2} />
                </div>
            </div>
        </div>
    )
}

export default Tables