import CodeBlock from "../components/CodeBlock"

const download = `
~~~html
<div class="download-card">
    <div class="download-card-header">
        <h6>
            Flow control solution MID DN25 for PRESTO A45/A45t/W50/W50t
        </h6>
    </div>
    <div class="download-card-content">
        <img class="download-card-content-image" src="..." alt="">
        <div class="download-card-content-details">
            <h6 class="download-card-content-details-title" aria-label="Title: Produktdatenblatt">
                Produktdatenblatt
            </h6>
            <span class="download-card-content-details-subtitle" aria-label="Language: Englisch">
                Englisch
            </span>
            <div class="download-card-content-details-file">
                <span aria-label="File type: PDF">PDF</span>
                <span class="w-100" aria-label="File size: 127 KB">127 KB</span>
            </div>
            <button type="button" class="download-card-content-details-download btn btn-primary" aria-label="Download">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M360 431.1H24c-13.25 0-24 10.76-24 24.02C0 469.2 10.75 480 24 480h336c13.25 0 24-10.76 24-24.02C384 442.7 373.3 431.1 360 431.1zM302.5 207.7L216 299.7v-243.6C216 42.76 205.3 32 192 32S168 42.76 168 56.02v243.6L81.47 207.7C76.75 202.6 70.38 200.1 64 200.1c-5.906 0-11.81 2.158-16.44 6.536c-9.656 9.069-10.12 24.27-1.031 33.93l128 136.1c9.062 9.694 25.88 9.694 34.94 0l128-136.1c9.094-9.663 8.625-24.86-1.031-33.93C326.8 197.5 311.6 197.1 302.5 207.7z" /></svg>
            </button>
        </div>
    </div>
</div>
~~~
`

const Cards = ({ title }) => {
    return (
        <div className="block block-accordion">
            <h1>{title}</h1>
            <div className="row g-4">
                <div className="col-12">
                    <h3>Download</h3>
                    <div class="download-card mb-3">
                        <div class="download-card-header">
                            <h6>
                                Flow control solution MID DN25 for PRESTO A45/A45t/W50/W50t
                            </h6>
                        </div>
                        <div class="download-card-content">
                            <img class="download-card-content-image"
                                src="https://www.julabo.com/sites/default/files/styles/download_thumbnail_2x/public/thumbnail/broschuren/MAGIO_K%C3%A4lte-_und_W%C3%A4rmethermostjpg_2.jpg?itok=Rbl5fc4u"
                                alt=""></img>
                            <div class="download-card-content-details">
                                <h6 class="download-card-content-details-title"
                                    aria-label="Title: Produktdatenblatt">
                                    Produktdatenblatt</h6>
                                <span class="download-card-content-details-subtitle"
                                    aria-label="Language: Englisch">Englisch</span>
                                <div class="download-card-content-details-file">
                                    <span aria-label="File type: PDF">PDF</span>
                                    <span class="w-100" aria-label="File size: 127 KB">127 KB</span>
                                </div>
                                <button type="button"
                                    class="download-card-content-details-download btn btn-primary"
                                    aria-label="Download">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path
                                            d="M360 431.1H24c-13.25 0-24 10.76-24 24.02C0 469.2 10.75 480 24 480h336c13.25 0 24-10.76 24-24.02C384 442.7 373.3 431.1 360 431.1zM302.5 207.7L216 299.7v-243.6C216 42.76 205.3 32 192 32S168 42.76 168 56.02v243.6L81.47 207.7C76.75 202.6 70.38 200.1 64 200.1c-5.906 0-11.81 2.158-16.44 6.536c-9.656 9.069-10.12 24.27-1.031 33.93l128 136.1c9.062 9.694 25.88 9.694 34.94 0l128-136.1c9.094-9.663 8.625-24.86-1.031-33.93C326.8 197.5 311.6 197.1 302.5 207.7z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <CodeBlock markdown={download} />
                </div>
            </div>
        </div>
    )
}

export default Cards