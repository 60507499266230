import Button from "../components/Button"
import CodeBlock from "../components/CodeBlock"
import { ReactComponent as Tag } from "../bin/icons/tag.svg"

const primary = `
~~~html
<button className="btn btn-primary">
    Button
</button>
~~~
`

const primaryV2 = `
~~~html
<button className="btn btn-primary-v2">
    Button
</button>
~~~
`

const secondary = `
~~~html
<button className="btn btn-secondary">
    Button
</button>
~~~
`

const secondaryV2 = `
~~~html
<button className="btn btn-secondary-v2">
    Button
</button>
~~~
`

const tooltipLeft = `
~~~html
<div className="btn-tooltip-wrapper">
    <button type="button" className="btn btn-primary btn-with-tooltip btn-with-tooltip-left">
        With Tooltip left
    </button>
    <div className="btn-tooltip-hint">
        <span>Text Tooltip</span>
    </div>
</div>
~~~
`

const tooltipTop = `
~~~html
<div className="btn-tooltip-wrapper">
    <button type="button" className="btn btn-primary btn-with-tooltip btn-with-tooltip-top">
        With Tooltip left
    </button>
    <div className="btn-tooltip-hint">
        <span>Text Tooltip</span>
    </div>
</div>
~~~
`

const nativeIcon = `
~~~html
<button className="btn btn-primary btn-with-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L418.7 226.7C443.7 251.7 443.7 292.3 418.7 317.3L285.3 450.7C260.3 475.7 219.7 475.7 194.7 450.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"></path></svg>
    <span>Text</span>
</button>
~~~
`

const Buttons = ({ title }) => {
    return (
        <div className="block block-buttons">
            <h1>{title}</h1>
            <div className="row g-4">
                <div className="col-12">
                    <Button type="primary" title="Primary" />
                    <CodeBlock markdown={primary} />
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12">
                    <div className="bg-darkEmerald border-rounded p-3">
                        <Button type="primary-v2" title="Primary-V2" customClass="mb-3" />
                        <CodeBlock markdown={primaryV2} />
                    </div>
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12">
                    <Button type="secondary" title="Secondary" />
                    <CodeBlock markdown={secondary} />
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12">
                    <div className="bg-gray73 border-rounded p-3">
                        <Button type="secondary-v2" title="Secondary-V2" customClass="mb-3" />
                        <CodeBlock markdown={secondaryV2} />
                    </div>
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12">
                    <div className="btn-tooltip-wrapper">
                        <button type="button" className="btn btn-primary btn-with-tooltip btn-with-tooltip-left">
                            With Tooltip left
                        </button>
                        <div className="btn-tooltip-hint">
                            <span>Text Tooltip</span>
                        </div>
                    </div>
                    <CodeBlock markdown={tooltipLeft} />
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12">
                    <div className="btn-tooltip-wrapper">
                        <button type="button" className="btn btn-secondary btn-with-tooltip btn-with-tooltip-top">
                            With Tooltip top
                        </button>
                        <div className="btn-tooltip-hint">
                            <span>Text Tooltip</span>
                        </div>
                    </div>
                    <CodeBlock markdown={tooltipTop} />
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-12">
                    <button type="button" className="btn btn-primary btn-with-icon">
                        <Tag />
                        <span>With native Icon</span>
                    </button>
                    <CodeBlock markdown={nativeIcon} />
                    <div className="info-block">
                        The icon can be placed anywhere. It is important that text is in the <span className="code">{`<span>Text</span>`}</span> tag. The color of the icon is automatically adjusted with the button variant or can be overwritten with the color option: <span className="code">fill-colorId</span>.
                    </div>
                </div>
                <div className="col-12">
                    <hr></hr>
                </div>
                {/* <div className="col-12">
                    <button type="button" className="btn btn-primary btn-with-icon">
                        <Tag />
                        <span>XMark</span>
                    </button>
                    <CodeBlock markdown={nativeIcon} />
                </div> */}
            </div>
        </div>
    )
}

export default Buttons