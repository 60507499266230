import React from "react"
import "github-markdown-css/github-markdown-light.css"
import Header from "./components/Header"
import Colors from "./templates/Colors"
import colorData from "./bin/data/colors.json"
import Buttons from "./templates/Buttons"
import Typography from "./templates/Typography"
import ListStyles from "./templates/ListStyles"
import Tables from "./templates/Tables"
import Border from "./templates/Border"
import Input from "./templates/Input"
import Accordion from "./templates/Accordion"
import Cards from "./templates/Cards"
import Animations from "./templates/Animations"
import InfoBoxes from "./templates/InfoBoxes";

const App = () => {
  return (
    <div className="t-theme">
      <Header />
      <br></br>
      <div className="container">
        <Colors title="Colors" colors={colorData} breakpoints="col-12 col-sm-6 col-md-4 col-lg-3" />
        <Typography title="Typography" />
        <Buttons title="Buttons" />
        <Border title="Border" />
        <Tables title="Tables" />
        <ListStyles title="List styles" />
        <Input title="Input" />
        <Cards title="Cards" />
        <Accordion title="Accordion" />
        <Animations title="Animations" />
        <InfoBoxes title="Infobox" />
      </div>
    </div>
  )
}

export default App