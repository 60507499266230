const InfoBox = ({title = '', links}) => {
    return (
        <div className="infobox">
            <div className="icon">
                <h4>{title}</h4>
            </div>
            <ul className="list-check-solid">
                {links && links.map(link => (
                    <li><a href={link.url}>{link.title}</a></li>
                ))}
            </ul>
        </div>
    )
}

export default InfoBox