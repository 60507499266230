import {ReactComponent as Logo} from "../bin/assets/logo.svg"

const Header = () => {
    return (
        <nav className="container-fluid navbar">
            <div className="container">
                <div className="row">
                    <div className="navbar-brand">
                        <Logo />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header