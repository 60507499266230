import InfoBox from "../components/InfoBox";
import CodeBlock from "../components/CodeBlock"

const boxTitle = 'Headline'
const links = [{title: 'Link 1', url:'#'}, {title: 'Link 2', url:'#'}]
const infoBoxes = `
~~~html
<div class="infobox">
    <div class="icon">
        <h4>Headline</h4>
    </div>
    <ul class="list-check-solid">
        <li><a href="#">Link1</a></li>
        <li><a href="#">Link2</a></li>
    </ul>
</div>
~~~
`

const InfoBoxes = ({ title }) => {
    return (
        <div className="block block-buttons">
            <h1>{title}</h1>
            <div className="row g-4">
                <div className="col-12">
                    <InfoBox title={boxTitle} links={links} />
                    <CodeBlock markdown={infoBoxes} />
                </div>
            </div>
        </div>
    )
}

export default InfoBoxes