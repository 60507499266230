import {ReactComponent as Copy} from "../bin/assets/copy.svg"
import copy from "copy-to-clipboard"

const CopyButton = ({code}) => {
    return (
        <Copy onClick={(e) => {copy(code)}} className="color-copy copy-button" />
    )
}

export default CopyButton
