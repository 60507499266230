import CodeBlock from "../components/CodeBlock"

const accordionLarge = `
~~~html
<div class="accordion-item mb-2">
    <input type="checkbox" class="accordion-item-check" id="accordion-item-1"></input>
    <label class="accordion-item-label" for="accordion-item-1">
        Wie viele Ausbildungsberufe bietet JULABO an?
    </label>
    <div class="accordion-item-content">
        <p>Wir bilden aktuell in acht Ausbildungsberufen und sechs dualen Studiengängen aus.</p>
    </div>
</div>
~~~
`

const accordionSmall = `
~~~html
<div class="accordion-item-small">
    <input type="checkbox" class="accordion-item-small-check" id="accordion-item-small-1"></input>
    <label class="accordion-item-small-label" for="accordion-item-small-1">
        Alle PRESTO A45/A45t
    </label>
    <div class="accordion-item-small-content">
        <ul>
            <li>
                ...
            </li>
        </ul>
    </div>
</div>
~~~
`

const Accordion = ({ title }) => {
    return (
        <div className="block block-accordion">
            <h1>{title}</h1>
            <div className="row g-4">
                <div className="col-12">
                    <h3>Large</h3>
                    <div className="accordion-item mb-2">
                        <input type="checkbox" className="accordion-item-check" id="accordion-item-1"></input>
                        <label className="accordion-item-label" for="accordion-item-1">
                            Wie viele Ausbildungsberufe bietet JULABO an?
                        </label>
                        <div className="accordion-item-content">
                            <p>Wir bilden aktuell in acht Ausbildungsberufen und sechs dualen Studiengängen aus.</p>
                        </div>
                    </div>
                    <CodeBlock markdown={accordionLarge} />
                </div>

                <div className="col-12">
                    <h3>Small</h3>
                    <div className="accordion-item-small mb-3">
                        <input type="checkbox" className="accordion-item-small-check" id="accordion-item-small-1"></input>
                        <label className="accordion-item-small-label" for="accordion-item-small-1">
                            Alle PRESTO A45/A45t
                        </label>
                        <div className="accordion-item-small-content">
                            <ul>
                                <li>
                                    ...
                                </li>
                            </ul>
                        </div>
                    </div>
                    <CodeBlock markdown={accordionSmall} />
                </div>
            </div>
        </div>
    )
}

export default Accordion