import ReactMarkdown from "react-markdown"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import CopyButton from "../components/CopyButton"

const CodeBlock = ({markdown}) => {
    return (
        <ReactMarkdown
            children={markdown}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '')
                    const code = String(children).replace(/\n$/, '')

                    return !inline && match ? (
                        <div className="code">
                            <SyntaxHighlighter
                                className="code-block"
                                children={code}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            />
                            <CopyButton code={code} />
                        </div>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    )
                }
            }}
        />
    )
}

export default CodeBlock