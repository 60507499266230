import CodeBlock from "../components/CodeBlock"

const circleCheck = `
~~~html
<svg class="animated-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="animated-check-circle" cx="26" cy="26" r="25" fill="none"></circle> <path class="animated-check-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path></svg>
~~~
`

const Animations = ({ title }) => {
    return (
        <div className="block block-accordion">
            <h1>{title}</h1>
            <div className="row g-4">
                <div className="col-12">
                    <h3>Circle Check</h3>
                    <svg className="animated-check mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="animated-check-circle" cx="26" cy="26" r="25" fill="none"></circle> <path class="animated-check-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path></svg>
                    <CodeBlock markdown={circleCheck} />
                </div>
            </div>
        </div>
    )
}

export default Animations