import Color from "../components/Color"
import CodeBlock from "../components/CodeBlock"

const borderDotted = `
~~~html
<div className="border-dotted border-gray60">
    ...
</div>
~~~
`

const borderSolid = `
~~~html
<div className="border-solid border-gray30Hover">
    ...
</div>
~~~
`

const borderRounded = `
~~~html
<div className="border-rounded bg-gray60">
    ...
</div>
~~~
`

const Border = ({ title }) => {
    return (
        <div className="block block-border">
            <h1>{title}</h1>
            <div className="row g-4">
                <div className="col-12">
                    <div className="info-block">
                        The following border options are available: <span className="code">border-solid</span> <span className="code">border-dotted</span> <span className="code">border-rounded</span>. You need to combine visible options (e. g. <span className="code">border-dotted</span>) with the color option: <span className="code">border-colorId</span>.
                    </div>
                </div>
                <div className="col-12">
                    <h3>Border solid</h3>
                    <div className="border-solid border-gray30Hover p-3">
                        <CodeBlock markdown={borderSolid} />
                    </div>
                </div>
                <div className="col-12">
                    <h3>Border dotted</h3>
                    <div className="border-dotted border-gray60 p-3">
                        <CodeBlock markdown={borderDotted} />
                    </div>
                </div>
                <div className="col-12">
                    <h3>Rounded corners</h3>
                    <div className="border-rounded bg-gray60 p-3">
                        <CodeBlock markdown={borderRounded} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Border