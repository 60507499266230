import React from "react"
import CodeBlock from "../components/CodeBlock"

const select = `
~~~html
<select id="countries" name="countries" title="Country selection">
    <option disabled selected value>Select</option>
    <option value="DE">Germany</option>
</select>
~~~
`

const input = `
~~~html
<input type="text" />
~~~
`

const inputError = `
~~~html
<div class="input-wrapper d-flex flex-column gap-1">
    <div class="input-elements">
        <input id="working_temperature_range_to" type="number" name="working_temperature_range_to" class="error" />
    </div>
    <div class="error-message">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="error-message-icon"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"></path></svg>
        <p class="error-message-text">Max. 350 C°</p>
    </div>
</div>
~~~
`

const checkbox = `
~~~html
<div class="input-wrapper">
    <div class="input-elements">
        <input id="digital_interface" type="checkbox" name="Ethernet" />
        <label class="input-label" htmlFor="digital_interface">
            <span>Ethernet</span>
        </label>
    </div>
</div>
`

const Input = ({ title }) => {
    return (
        <div className="block block-input">
            <h1>{title}</h1>
            <div className="row g-4">
                <div className="col-12">
                    <h3>Select</h3>
                    <select name="countries" title="countries" id="countries" defaultValue="disabled">
                        <option disabled={true} value="disabled">Select</option>
                        <option value="DE">Germany</option>
                    </select>
                    <CodeBlock markdown={select} />
                </div>
                <div className="col-12">
                    <h3>Input</h3>
                    <input type="text" />
                    <CodeBlock markdown={input} />
                    <div className="info-block">
                        Possible input types: <span className="code">text</span> <span className="code">number</span> <span className="code">password</span> <span className="code">search</span>
                    </div>
                </div>
                <div className="col-12">
                    <h3>Input Error</h3>
                    <div className="input-wrapper d-flex flex-column gap-1">
                        <div className="input-elements">
                            <input id="working_temperature_range_to" type="number" name="working_temperature_range_to" className="error" />
                        </div>
                        <div className="error-message">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="error-message-icon"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"></path></svg>
                            <p className="error-message-text">Max. 350 C°</p>
                        </div>
                    </div>
                    <CodeBlock markdown={inputError} />
                </div>
                <div className="col-12">
                    <h3>Checkbox</h3>
                    <div className="input-wrapper">
                        <div className="input-elements">
                            <input id="digital_interface_1" type="checkbox" name="digital_interface_1" />
                            <label className="input-label" htmlFor="digital_interface_1">
                                <span>Ethernet</span>
                            </label>
                        </div>
                    </div>
                    <CodeBlock markdown={checkbox} />
                </div>
            </div>
        </div>
    )
}

export default Input